import apiTamoJunto from "@/services/apiTamoJunto";
import AppError from "@/utils/appError";

const resource = "tabelas-venda";

const buscarEstados = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_TABELAS_VENDA } };
    const { data } = await apiTamoJunto.get(`${resource}/estados`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const buscarOperadoras = async (uf, filtrosProfissao, filtrosEntidade) => {
  try {
    const filtros = {
      filtroProfissao: "",
      filtroEntidade: "",
    };
    if (filtrosProfissao && filtrosProfissao.length) filtros.filtroProfissao = "?profissao=".concat(filtrosProfissao);
    if (filtrosEntidade && filtrosEntidade.length) filtros.filtroEntidade = `${filtros.filtroProfissao ? '&' : '?'}entidades=`.concat(filtrosEntidade.join());
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_TABELAS_VENDA } };
    const { data } = await apiTamoJunto.get(`${resource}/operadoras/${uf}${filtros.filtroProfissao}${filtros.filtroEntidade}`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const buscarProfissoes = async (uf, filtros, tipo) => {
  try {
    let filtro = "";
    if (tipo === "operadora") filtro = filtros && filtros.length ? "?operadoras=".concat(filtros.join()) : "";
    else if (tipo === "entidade") filtro = filtros && filtros.length ? "?entidades=".concat(filtros.join()) : "";
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_TABELAS_VENDA } };
    const { data } = await apiTamoJunto.get(`${resource}/profissoes/${uf}${filtro}`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const buscarEntidades = async (uf, filtrosProfissao, filtrosOperadora) => {
  try {
    const filtros = {
      filtroProfissao: "",
      filtroOperadora: "",
    };
    if (filtrosProfissao && filtrosProfissao.length) filtros.filtroProfissao = "?profissao=".concat(filtrosProfissao);
    if (filtrosOperadora && filtrosOperadora.length) filtros.filtroOperadora = `${filtros.filtroProfissao ? '&' : '?'}operadoras=`.concat(filtrosOperadora.join());
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_TABELAS_VENDA } };
    const { data } = await apiTamoJunto.get(`${resource}/entidades/${uf}${filtros.filtroProfissao}${filtros.filtroOperadora}`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const buscarTabelasVenda = async (uf, filtrosOperadora, filtrosProfissao, filtrosEntidade) => {
  try {
    const filtros = {
      filtroOperadora: "",
      filtroProfissao: "",
      filtroEntidade: "",
    };
    if (filtrosOperadora && filtrosOperadora.length) filtros.filtroOperadora = "?operadoras=".concat(filtrosOperadora.join());
    if (filtrosProfissao && filtrosProfissao.length) filtros.filtroProfissao = `${filtros.filtroOperadora ? '&' : '?'}profissao=`.concat(filtrosProfissao);
    if (filtrosEntidade && filtrosEntidade.length) filtros.filtroEntidade = `${filtros.filtroOperadora || filtros.filtroProfissao ? '&' : '?'}entidades=`.concat(filtrosEntidade.join());
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_TABELAS_VENDA } };
    const { data } = await apiTamoJunto.get(`${resource}/${uf}${filtros.filtroOperadora}${filtros.filtroProfissao}${filtros.filtroEntidade}`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  buscarEstados,
  buscarOperadoras,
  buscarProfissoes,
  buscarEntidades,
  buscarTabelasVenda,
};
