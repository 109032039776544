<template>
  <div>
    <the-toolbar title="Tabelas de Venda" />
    <div v-if="loading" class="d-flex justify-center mt-10 pa-10">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <ResultadoTabelasVenda v-if="showResult && !loading" :form="form" :tabelasVendaList="tabelasVendaList" @nova-busca="onClickNovaBusca()" @submit="onClickSubmit" />
    <v-container v-show="!showResult && !loading">
      <Busca ref="busca" :showDetails="true" @submit="onClickSubmit" @loading="onLoading" />
      <Informacao />
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TheToolbar from "@/components/TheToolbar.vue";
import ResultadoTabelasVenda from "./components/ResultadoTabelasVenda.vue";
import Informacao from "./components/Informacao.vue";
import Busca from "./components/Busca.vue";
import { cloneDeep } from "lodash";

export default {
  name: "tabelas-venda",
  components: { TheToolbar, ResultadoTabelasVenda, Informacao, Busca },
  data() {
    return {
      tabelasVendaList: [],
      form: {
        estado: {},
        operadoraList: [],
        profissao: null,
        entidadeList: [],
      },
      showResult: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user.profile }),
  },
  methods: {
    onClickNovaBusca() {
      this.$refs.busca.limparCampos();
      this.showResult = false;
    },
    onClickSubmit(form, tabelasVendaList) {
      this.form = cloneDeep(form);
      this.tabelasVendaList = cloneDeep(tabelasVendaList);
      this.showResult = true;
    },
    onLoading(flag) {
      this.loading = flag;
    },
  },
};
</script>
