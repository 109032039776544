<template>
  <div>
    <v-container>
      <v-row>
        <v-col md="9">
          <p class="default-margin default-text">Você está vendo resultados para:</p>
          <ul class="mt-5 default-text">
            <li>
              Região: <strong>{{ form.estado && form.estado.nome ? form.estado.nome : "-" }}</strong>
            </li>
            <li class="my-3">
              Profissão: <strong>{{ form.profissao && form.profissao.length ? form.profissao : "-" }}</strong>
            </li>
            <li>
              Entidades: <strong>{{ form.entidadeList && form.entidadeList.length ? form.entidadeList.join(", ") : "-" }}</strong>
            </li>
            <li class="my-3">
              Operadoras:
              <strong>
                {{ form.operadoraList && form.operadoraList.length ? form.operadoraList.join(", ") : "-" }}
              </strong>
            </li>
          </ul>
        </v-col>
        <v-col md="3" align-self="end">
          <v-row>
            <v-col>
              <v-btn color="warning" @click="onClickRefinarBusca(form)">
                <v-icon class="mr-2">{{ !showFilters ? "mdi-magnify-plus-outline" : "mdi-magnify-minus-outline" }}</v-icon>
                <span class="mt-1"> Refinar Busca </span>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="primary" @click="$emit('nova-busca')">
                <v-icon class="mr-2">mdi-plus</v-icon>
                <span class="mt-1"> Nova Busca </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="showFilters">
        <Busca ref="busca" :showDetails="false" @submit="onClickSubmit" :filters="filters" />
      </v-row>
      <v-row>
        <Card v-for="(tabelaVenda, index) in tabelasVendaList" :key="'tabelaVenda' + index" :item="tabelaVenda" />
      </v-row>
      <Informacao />
    </v-container>
  </div>
</template>

<script>
import Informacao from "./Informacao.vue";
import Card from "./Card.vue";
import Busca from "./Busca.vue";
import { cloneDeep } from "lodash";

export default {
  name: "resultado-tabelas-venda",
  components: { Informacao, Card, Busca },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    tabelasVendaList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showFilters: false,
      filters: {},
    };
  },
  methods: {
    onClickRefinarBusca(form) {
      this.filters = cloneDeep(form);
      this.showFilters = !this.showFilters;
    },
    onClickSubmit(form, tabelasVendaList) {
      this.$emit("submit", form, tabelasVendaList);
    },
  },
};
</script>

<style lang="scss" scoped>
.default-text {
  font-size: 18px;
}

.default-margin {
  margin: 2.5rem 0 2.5rem 0;
}
</style>
