<template>
  <div class="info-margin">
    <p>Preços e condições obtidos pela negociação coletiva da Qualicorp com as operadoras parceiras, para o mês atual.</p>
    <p>
      Estas condições valem para planos de saúde coletivos por adesão, de acordo com as regras da ANS. Este site foi criado pela Qualicorp, com informações resumidas, para auxiliar os interessados em
      contratar um desses planos, permitindo baixar tabelas de venda para análise e comparação das opções.
    </p>
    <p>
      A Qualicorp age de boa fé e faz o possível para garantir a confiabilidade das informações deste site, podendo alterar ou corrigir os arquivos disponíveis neste site a qualquer momento, sem aviso
      prévio.
    </p>
  </div>
</template>

<script>
export default {
  name: "informacao",
  props: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.info-margin {
  margin-top: 3rem;
}
</style>
