<template>
  <v-col>
    <div class="default-card">
      <div class="card-header">
        <v-img max-height="90%" max-width="100%" contain :src="item.logoOperadora"></v-img>
      </div>
      <div class="container-botoes">
        <div class="card-botao text-center" @click="redirectTo(item.linkTabela)">
          <v-row>
            <v-col>
              <v-icon color="primary" class="mt-3">mdi-eye</v-icon>
            </v-col>
          </v-row>
          Ver
        </div>
        <div class="card-botao text-center" @click="redirectTo(item.linkTabela)">
          <v-row>
            <v-col>
              <v-icon color="primary" class="mt-3">mdi-download</v-icon>
            </v-col>
          </v-row>
          Baixar
        </div>
        <div class="card-botao text-center" @click="mailTo()">
          <v-row>
            <v-col>
              <v-icon color="primary" class="mt-3">mdi-send</v-icon>
            </v-col>
          </v-row>
          Enviar
        </div>
      </div>
      <div class="card-entidade mb-5">
        {{ item.entidade }}
      </div>
      <div v-if="item.linkAdesao" class="card-link">
        <a :href="item.linkAdesao" target="_blank">
          Guia do consultor
        </a>
      </div>
      <div v-if="item.linkFiliacao" class="card-link mt-2">
        <a :href="item.linkFiliacao" target="_blank">
          Filiação: Acesse o link
        </a>
      </div>
      <div v-if="item.linkAditivo" class="card-link mt-2">
        <a :href="item.linkAditivo" target="_blank">
          Aditivo: Acesse o link
        </a>
      </div>
      <div v-if="item.linkOutrosDocumentos" class="card-link mt-2">
        <a :href="item.linkOutrosDocumentos" target="_blank">
          Outros documentos: Acesse o link
        </a>
      </div>
      <div v-if="item.publico === 4" class="card-link card-public mt-2">
        <p>
          Atenção! Esse produto está em comercialização no sistema Planium.
        </p>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "card",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    redirectTo(url) {
      if (url) {
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.target = "_blank";
        anchor.click();
      } else {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao abrir link, tente novamente mais tarde.",
        });
      }
    },
    mailTo() {
      if (this.item.linkTabela) {
        navigator.share({
          title: "Tabela de venda",
          text: "Para acessar a tabela de venda, basta clicar no link a seguir: ",
          url: this.item.linkTabela,
        });
      } else {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao enviar e-mail, tente novamente mais tarde.",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

.default-card {
  margin-top: 3rem;
  width: 18rem;
  height: 22rem;
  padding: 16px;
  border: 2px solid #cccac9;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
}

.card-header {
  display: flex;
  width: 100%;
  height: 56px;
  border-bottom: 1px solid $azul-qualicorp-hex;
}

.card-botao {
  width: 5rem;
  height: 4rem;
  border: 1px solid #cccac9;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.container-botoes {
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 60px;
  margin-top: 32px;
}

.card-entidade {
  margin-top: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1a1919;
}

.card-link {
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $azul-qualicorp-hex;
}
</style>
