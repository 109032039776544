var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.showDetails
      ? _c("div", [
          _c("p", { staticClass: "default-margin default-text" }, [
            _vm._v(
              "Nas tabelas de venda, você vai encontrar as seguintes informações:"
            ),
          ]),
          _vm._m(0),
        ])
      : _vm._e(),
    _vm.estadoList.length
      ? _c("div", [
          _vm.showDetails
            ? _c(
                "div",
                [
                  _c("p", { staticClass: "default-margin default-text" }, [
                    _vm._v(
                      "Pra começar, escolha o estado em que você quer fazer a busca:"
                    ),
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { sm: "6", lg: "3" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.estadoList,
                              id: "estado",
                              name: "estado",
                              label: "Estado",
                              mode: "aggressive",
                              "item-text": "nome",
                              outlined: "",
                              "return-object": "",
                            },
                            on: { change: _vm.onChangeEstado },
                            model: {
                              value: _vm.estadoSel,
                              callback: function ($$v) {
                                _vm.estadoSel = $$v
                              },
                              expression: "estadoSel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form && _vm.form.estado && _vm.form.estado.uf
            ? _c(
                "div",
                [
                  _c(
                    "p",
                    { staticClass: "default-margin smaller default-text" },
                    [_vm._v("Como você quer iniciar a sua busca?")]
                  ),
                  _c(
                    "validation-observer",
                    { ref: "form" },
                    [
                      _c(
                        "v-form",
                        {
                          staticClass: "mt-4",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submit()
                            },
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mt-5" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "operadora",
                                      vid: "operadora",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  items: _vm.operadoraList,
                                                  id: "operadora",
                                                  name: "operadora",
                                                  label: "Operadora",
                                                  mode: "aggressive",
                                                  "item-text": "nome",
                                                  outlined: "",
                                                  multiple: "",
                                                  clearable: "",
                                                  "error-messages": errors,
                                                  "search-input":
                                                    _vm.operadoraInput,
                                                },
                                                on: {
                                                  "update:searchInput":
                                                    function ($event) {
                                                      _vm.operadoraInput =
                                                        $event
                                                    },
                                                  "update:search-input":
                                                    function ($event) {
                                                      _vm.operadoraInput =
                                                        $event
                                                    },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    !_vm.operadoraInput
                                                      ? {
                                                          key: "prepend-item",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  attrs: {
                                                                    ripple: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.selectAll(
                                                                          "operadora"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            "Selecionar todos"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("v-divider", {
                                                                staticClass:
                                                                  "mt-2",
                                                              }),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        }
                                                      : null,
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.form.operadoraList,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "operadoraList",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.operadoraList",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1416935315
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "profissao",
                                      vid: "profissao",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  items: _vm.profissaoList,
                                                  id: "profissao",
                                                  name: "profissao",
                                                  label: "Profissão",
                                                  mode: "aggressive",
                                                  "item-text": "nome",
                                                  outlined: "",
                                                  clearable: "",
                                                  "error-messages": errors,
                                                },
                                                model: {
                                                  value: _vm.form.profissao,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "profissao",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.profissao",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1105952647
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "entidade",
                                      vid: "entidade",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  items: _vm.entidadeList,
                                                  id: "entidade",
                                                  name: "entidade",
                                                  label: "Entidade",
                                                  mode: "aggressive",
                                                  "item-text": "nome",
                                                  outlined: "",
                                                  multiple: "",
                                                  clearable: "",
                                                  "error-messages": errors,
                                                  "search-input":
                                                    _vm.entidadeInput,
                                                },
                                                on: {
                                                  "update:searchInput":
                                                    function ($event) {
                                                      _vm.entidadeInput = $event
                                                    },
                                                  "update:search-input":
                                                    function ($event) {
                                                      _vm.entidadeInput = $event
                                                    },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    !_vm.entidadeInput
                                                      ? {
                                                          key: "prepend-item",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  attrs: {
                                                                    ripple: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.selectAll(
                                                                          "entidade"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            "Selecionar todos"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("v-divider", {
                                                                staticClass:
                                                                  "mt-2",
                                                              }),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        }
                                                      : null,
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.form.entidadeList,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "entidadeList",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.entidadeList",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3652010323
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "secundaryBtn--text mt-5",
                              attrs: {
                                disabled: !_vm.flagCamposBuscaPreenchidos,
                                color: "primary",
                                type: "submit",
                                block: _vm.$vuetify.breakpoint.xs,
                              },
                            },
                            [
                              _c("span", { staticClass: "mt-1" }, [
                                _vm._v("Buscar"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "mt-5 default-text" }, [
      _c("li", [
        _c("strong", [_vm._v("Regiões")]),
        _vm._v(" em que você pode vender cada tipo de plano;"),
      ]),
      _c("li", { staticClass: "my-3" }, [
        _c("strong", [_vm._v("Valores vigentes")]),
        _vm._v(" para cada plano;"),
      ]),
      _c("li", [
        _c("strong", [_vm._v("Entidades aceitas")]),
        _vm._v(" por cada operadora."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }