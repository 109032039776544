var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-col", [
    _c("div", { staticClass: "default-card" }, [
      _c(
        "div",
        { staticClass: "card-header" },
        [
          _c("v-img", {
            attrs: {
              "max-height": "90%",
              "max-width": "100%",
              contain: "",
              src: _vm.item.logoOperadora,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "container-botoes" }, [
        _c(
          "div",
          {
            staticClass: "card-botao text-center",
            on: {
              click: function ($event) {
                return _vm.redirectTo(_vm.item.linkTabela)
              },
            },
          },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mt-3", attrs: { color: "primary" } },
                      [_vm._v("mdi-eye")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" Ver "),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "card-botao text-center",
            on: {
              click: function ($event) {
                return _vm.redirectTo(_vm.item.linkTabela)
              },
            },
          },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mt-3", attrs: { color: "primary" } },
                      [_vm._v("mdi-download")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" Baixar "),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "card-botao text-center",
            on: {
              click: function ($event) {
                return _vm.mailTo()
              },
            },
          },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mt-3", attrs: { color: "primary" } },
                      [_vm._v("mdi-send")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" Enviar "),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "card-entidade mb-5" }, [
        _vm._v(" " + _vm._s(_vm.item.entidade) + " "),
      ]),
      _vm.item.linkAdesao
        ? _c("div", { staticClass: "card-link" }, [
            _c(
              "a",
              { attrs: { href: _vm.item.linkAdesao, target: "_blank" } },
              [_vm._v(" Guia do consultor ")]
            ),
          ])
        : _vm._e(),
      _vm.item.linkFiliacao
        ? _c("div", { staticClass: "card-link mt-2" }, [
            _c(
              "a",
              { attrs: { href: _vm.item.linkFiliacao, target: "_blank" } },
              [_vm._v(" Filiação: Acesse o link ")]
            ),
          ])
        : _vm._e(),
      _vm.item.linkAditivo
        ? _c("div", { staticClass: "card-link mt-2" }, [
            _c(
              "a",
              { attrs: { href: _vm.item.linkAditivo, target: "_blank" } },
              [_vm._v(" Aditivo: Acesse o link ")]
            ),
          ])
        : _vm._e(),
      _vm.item.linkOutrosDocumentos
        ? _c("div", { staticClass: "card-link mt-2" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.item.linkOutrosDocumentos,
                  target: "_blank",
                },
              },
              [_vm._v(" Outros documentos: Acesse o link ")]
            ),
          ])
        : _vm._e(),
      _vm.item.publico === 4
        ? _c("div", { staticClass: "card-link card-public mt-2" }, [
            _c("p", [
              _vm._v(
                " Atenção! Esse produto está em comercialização no sistema Planium. "
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }