<template>
  <div>
    <div v-if="showDetails">
      <p class="default-margin default-text">Nas tabelas de venda, você vai encontrar as seguintes informações:</p>
      <ul class="mt-5 default-text">
        <li><strong>Regiões</strong> em que você pode vender cada tipo de plano;</li>
        <li class="my-3"><strong>Valores vigentes</strong> para cada plano;</li>
        <li><strong>Entidades aceitas</strong> por cada operadora.</li>
      </ul>
    </div>
    <div v-if="estadoList.length">
      <div v-if="showDetails">
        <p class="default-margin default-text">Pra começar, escolha o estado em que você quer fazer a busca:</p>
        <v-row>
          <v-col sm="6" lg="3">
            <v-autocomplete :items="estadoList" id="estado" name="estado" label="Estado" v-model="estadoSel" mode="aggressive" :item-text="'nome'" outlined return-object @change="onChangeEstado" />
          </v-col>
        </v-row>
      </div>
      <div v-if="form && form.estado && form.estado.uf">
        <p class="default-margin smaller default-text">Como você quer iniciar a sua busca?</p>
        <validation-observer ref="form">
          <v-form class="mt-4" @submit.prevent="submit()">
            <v-row class="mt-5">
              <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" name="operadora" vid="operadora">
                  <v-autocomplete
                    :items="operadoraList"
                    id="operadora"
                    name="operadora"
                    label="Operadora"
                    v-model="form.operadoraList"
                    mode="aggressive"
                    :item-text="'nome'"
                    outlined
                    multiple
                    clearable
                    :error-messages="errors"
                    :search-input.sync="operadoraInput"
                  >
                    <template v-if="!operadoraInput" v-slot:prepend-item>
                      <v-list-item ripple @click="selectAll('operadora')">
                        <v-list-item-content>
                          <v-list-item-title>Selecionar todos</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" name="profissao" vid="profissao">
                  <v-autocomplete
                    :items="profissaoList"
                    id="profissao"
                    name="profissao"
                    label="Profissão"
                    v-model="form.profissao"
                    mode="aggressive"
                    :item-text="'nome'"
                    outlined
                    clearable
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4">
                <validation-provider v-slot="{ errors }" name="entidade" vid="entidade">
                  <v-autocomplete
                    :items="entidadeList"
                    id="entidade"
                    name="entidade"
                    label="Entidade"
                    v-model="form.entidadeList"
                    mode="aggressive"
                    :item-text="'nome'"
                    outlined
                    multiple
                    clearable
                    :error-messages="errors"
                    :search-input.sync="entidadeInput"
                  >
                    <template v-if="!entidadeInput" v-slot:prepend-item>
                      <v-list-item ripple @click="selectAll('entidade')">
                        <v-list-item-content>
                          <v-list-item-title>Selecionar todos</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>
            </v-row>
            <v-btn :disabled="!flagCamposBuscaPreenchidos" color="primary" type="submit" :block="$vuetify.breakpoint.xs" class="secundaryBtn--text mt-5">
              <span class="mt-1">Buscar</span>
            </v-btn>
          </v-form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TheToolbar from "@/components/TheToolbar.vue";
import tabelasVendaService from "@/services/tabelasVendaService";
import { cloneDeep, find, isEmpty } from "lodash";

export default {
  name: "busca",
  components: { TheToolbar },
  props: {
    showDetails: {
      type: Boolean,
      default: true,
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      estadoSel: null,
      estadoList: [],
      operadoraInput: null,
      operadoraList: [],
      profissaoList: [],
      entidadeInput: null,
      entidadeList: [],
      form: {
        estado: {},
        operadoraList: [],
        profissao: null,
        entidadeList: [],
      },
    };
  },
  /* eslint-disable */
  watch: {
    "form.operadoraList": function (filtros) {
      const { estado } = this.form;
      if (estado && estado.uf) {
        this.getProfissaoList(estado.uf, filtros, "operadora");
        this.getEntidadeList(estado.uf, this.form.profissao, this.form.operadoraList);
        this.operadoraInput = null;
      }
    },
    "form.profissao": function () {
      const { estado } = this.form;
      if (estado && estado.uf) {
        this.getOperadoraList(estado.uf, this.form.profissao, this.form.entidadeList);
        this.getEntidadeList(estado.uf, this.form.profissao, this.form.operadoraList);
      }
    },
    "form.entidadeList": function (filtros) {
      const { estado } = this.form;
      if (estado && estado.uf) {
        this.getOperadoraList(estado.uf, this.form.profissao, this.form.entidadeList);
        this.getProfissaoList(estado.uf, filtros, "entidade");
        this.entidadeInput = null;
      }
    },
  },
  /* eslint-enable */
  mounted() {
    this.getData();
  },
  computed: {
    ...mapState({ user: (state) => state.user.profile }),
    flagCamposBuscaPreenchidos() {
      const { operadoraList, profissao, entidadeList } = this.form;
      if (operadoraList.length || profissao || entidadeList.length) return true;
      return false;
    },
  },
  methods: {
    async getData() {
      try {
        this.$emit("loading", true);
        await this.getEstadoList();
        await this.getEstadoCorretor();
        if (!isEmpty(this.filters)) this.setFilters(this.filters);
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao buscar estados, tente novamente mais tarde.",
        });
      } finally {
        this.$emit("loading", false);
      }
    },
    async getEstadoList() {
      const { data } = await tabelasVendaService.buscarEstados();
      this.estadoList = data;
    },
    getEstadoCorretor() {
      const { endereco } = this.user;
      const { nome, uf } = find(this.estadoList, { uf: endereco.estado });
      this.form.estado = { nome, uf } || null;
      this.estadoSel = { nome, uf } || null;
      this.getCamposPrincipais();
    },
    setFilters(filters) {
      this.form = cloneDeep(filters);
    },
    onChangeEstado(data) {
      this.form.estado = cloneDeep(data);
      this.limparCampos();
    },
    limparCampos() {
      this.form = {
        ...this.form,
        operadoraList: [],
        profissao: null,
        entidadeList: [],
      };
      this.getCamposPrincipais();
    },
    getCamposPrincipais() {
      const { estado } = this.form;
      if (estado && estado.uf) {
        this.getOperadoraList(estado.uf);
        this.getProfissaoList(estado.uf);
        this.getEntidadeList(estado.uf);
      }
    },
    async getOperadoraList(uf, filtrosProfissao = [], filtrosEntidade = []) {
      try {
        const { data } = await tabelasVendaService.buscarOperadoras(uf, filtrosProfissao, filtrosEntidade);
        this.operadoraList = data;
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao buscar operadoras, tente novamente mais tarde.",
        });
      }
    },
    async getProfissaoList(uf, filtros = [], tipo = null) {
      try {
        const { data } = await tabelasVendaService.buscarProfissoes(uf, filtros, tipo);
        this.profissaoList = data;
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao buscar profissões, tente novamente mais tarde.",
        });
      }
    },
    async getEntidadeList(uf, filtrosProfissao = [], filtrosOperadora = []) {
      try {
        const { data } = await tabelasVendaService.buscarEntidades(uf, filtrosProfissao, filtrosOperadora);
        this.entidadeList = data;
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao buscar entidades, tente novamente mais tarde.",
        });
      }
    },
    selectAll(tipo) {
      if (tipo === "operadora") this.form.operadoraList = this.operadoraList.map((e) => e.nome);
      else if (tipo === "entidade") this.form.entidadeList = this.entidadeList.map((e) => e.nome);
    },
    async submit() {
      const formIsValid = await this.$refs.form.validate();
      if (formIsValid) {
        try {
          this.$emit("loading", true);
          const { estado, operadoraList, profissao, entidadeList } = this.form;
          const { data } = await tabelasVendaService.buscarTabelasVenda(estado.uf, operadoraList, profissao, entidadeList);
          this.$emit("submit", this.form, data);
        } catch (error) {
          this.$root.$snackBar.open({
            color: "error",
            message: "Erro ao buscar tabelas de venda, tente novamente mais tarde.",
          });
        } finally {
          this.$emit("loading", false);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.default-text {
  font-size: 18px;
}

.default-margin {
  margin: 2.5rem 0 2.5rem 0;
  &.smaller {
    margin: 2.5rem 0 1.5rem 0;
  }
}
</style>
