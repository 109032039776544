import axios from 'axios';
import { Capacitor } from '@capacitor/core'

const token = localStorage.getItem(`${process.env.VUE_APP_NAME_AUTH}`);

const baseDomain = process.env.VUE_APP_API_TAMOJUNTO_URL;
const api = axios.create({
  baseURL: baseDomain,
});

if (token && !Capacitor.isNativePlatform()) {
  api.defaults.headers.common.auth = token;
  //api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
}

export default api;
