var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "9" } }, [
                _c("p", { staticClass: "default-margin default-text" }, [
                  _vm._v("Você está vendo resultados para:"),
                ]),
                _c("ul", { staticClass: "mt-5 default-text" }, [
                  _c("li", [
                    _vm._v(" Região: "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.form.estado && _vm.form.estado.nome
                            ? _vm.form.estado.nome
                            : "-"
                        )
                      ),
                    ]),
                  ]),
                  _c("li", { staticClass: "my-3" }, [
                    _vm._v(" Profissão: "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.form.profissao && _vm.form.profissao.length
                            ? _vm.form.profissao
                            : "-"
                        )
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _vm._v(" Entidades: "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.form.entidadeList && _vm.form.entidadeList.length
                            ? _vm.form.entidadeList.join(", ")
                            : "-"
                        )
                      ),
                    ]),
                  ]),
                  _c("li", { staticClass: "my-3" }, [
                    _vm._v(" Operadoras: "),
                    _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.form.operadoraList &&
                              _vm.form.operadoraList.length
                              ? _vm.form.operadoraList.join(", ")
                              : "-"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { md: "3", "align-self": "end" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "warning" },
                              on: {
                                click: function ($event) {
                                  return _vm.onClickRefinarBusca(_vm.form)
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v(
                                  _vm._s(
                                    !_vm.showFilters
                                      ? "mdi-magnify-plus-outline"
                                      : "mdi-magnify-minus-outline"
                                  )
                                ),
                              ]),
                              _c("span", { staticClass: "mt-1" }, [
                                _vm._v(" Refinar Busca "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("nova-busca")
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("mdi-plus"),
                              ]),
                              _c("span", { staticClass: "mt-1" }, [
                                _vm._v(" Nova Busca "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showFilters
            ? _c(
                "v-row",
                [
                  _c("Busca", {
                    ref: "busca",
                    attrs: { showDetails: false, filters: _vm.filters },
                    on: { submit: _vm.onClickSubmit },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            _vm._l(_vm.tabelasVendaList, function (tabelaVenda, index) {
              return _c("Card", {
                key: "tabelaVenda" + index,
                attrs: { item: tabelaVenda },
              })
            }),
            1
          ),
          _c("Informacao"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }